const data = [
  
    {
     id: 4449,
     title: "SALE: Gold Yellow Bandhej Romance Suit (3 piece)",
     desc: "<div><br/><b>Special ocassions deserves elegant ensembles such as our Romance Suit Set.</b><br/><b>This is a 3 piece set including:</b><br/>Kurta pants Dupatta<br/>Three-quarter sleeves<br/>Nyra cut Kurta with Beautiful Handwork.<br/>Pants gives it a classy look put together.<br/>Pure cotton dupatta<br/>Material: Cotton<br/>Bust Size:<br/>M-38<br/>L-40<br/>XL-42<br/>XXL-44<br/></div>",
     price: 99,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/IMG-20220806-WA0032.jpg"]
    },
    {
     id: 4433,
     title: "Haldi Yellow Mirror Zari Slim Fit Suit Set",
     desc: "<div><br/>This is a two piece set includes kurta and pants.<br/>cotton kurta with cotton pants, Half drawstring and half elastic closure.<br/><ul class=\"iformation\"><br/> \t<li>MATERIALS: cotton mix</li><br/> \t<li>CARE: Hand Wash Separately</li><br/> \t<li>Go for a size up.</li><br/></ul><br/>M 38<br/>L 40<br/>XL 42<br/>XXL 44<br/><span data-mce-fragment=\"1\">Since these are slim fit, go for a size up your regular size. We have no returns/exchange as the range being very reasonable. We have sold 25000+ units - Feel free to read our instagram reviews - there are thounsands of customers pictures too. Since these units go sold out everytime in 1-2 hours of launch - we wont be able to assure same prints again once sold out. Please be little prompt and once you get your hands on them, you would love them again and again is what our regular buyers say! Happy shopping!</span><br/></div>",
     price: 129,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/WhatsAppImage2023-03-06at11.25.38PM.jpg"]
   },
   {
     id: 4436,
     title: "Lively Floral Slim Fit Suit",
     desc: "<div><br/><b>Summer ocassions or regular days deserves elegant ensembles such as our Relaxing cotton Suit Set.</b><br/><b>This is a 3 piece set including:</b><br/>Kurta pants Dupatta<br/><strong>MATERIAL</strong><br/>Cotton 40-40<br/>Soft Hand wash<br/>M 38<br/>L 40<br/>XL 42<br/>XXL 44<br/>Since these are slim fit, go for a size up your regular size. We have no returns/exchange as the range being very reasonable. We have sold 25000+ units - Feel free to read our instagram reviews - there are thounsands of customers pictures too. Since these units go sold out everytime in 1-2 hours of launch - we wont be able to assure same prints again once sold out. Please be little prompt and once you get your hands on them, you would love them again and again is what our regular buyers say! Happy shopping!<br/></div>",
     price: 149,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/8c145222-9158-4db4-bd8e-fcb484317637.jpg"]
   },
   {
     id: 4428,
     title: "Carrot Ikkat Slim Fit Suit Set",
     desc: "<div><br/>This is a two piece set includes kurta and pants.<br/>cotton kurta with cotton pants, Half drawstring and half elastic closure.<br/><ul class=\"iformation\"><br/> \t<li>MATERIALS: cotton</li><br/> \t<li>CARE: Hand Wash Separately</li><br/> \t<li>Go for a size up.</li><br/></ul><br/>M 38<br/>L 40<br/>XL 42<br/>XXL 44<br/></div>",
     price: 129,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/WhatsAppImage2023-02-17at2.01.26PM.jpg"]
   },
   {
     id: 4445,
     title: "Pink White Floral Slim Fit Suit",
     desc: "<div><br/><b>Summer ocassions or regular days deserves elegant ensembles such as our Relaxing cotton Suit Set.</b><br/><b>This is a 3 piece set including:</b><br/>Kurta pants Dupatta<br/><strong>MATERIAL</strong><br/>Cotton Mix<br/>Soft Hand wash<br/>M 38<br/>L 40<br/>XL 42<br/>XXL 44<br/>Since these are slim fit, <strong>go for a size up your regular size.</strong> We have no returns/exchange as the range being very reasonable. We have sold 25000+ units - Feel free to read our instagram reviews - there are thounsands of customers pictures too. Since these units go sold out everytime in 1-2 hours of launch - we wont be able to assure same prints again once sold out. Please be little prompt and once you get your hands on them, you would love them again and again is what our regular buyers say! Happy shopping!<br/></div>",
     price: 99,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/WhatsAppImage2023-03-14at9.39.12PM.jpg"]
   },
   {
     id: 4466,
     title: "Sky Blue flowers Slim Fit Suit Set",
     desc: "<div><br/>This is a two piece set includes kurta and pants.<br/>cotton kurta with cotton pants, Half drawstring and half elastic closure.<br/><ul class=\"iformation\"><br/> \t<li>MATERIALS: cotton mix</li><br/> \t<li>CARE: Hand Wash Separately</li><br/> \t<li>Go for a size up.</li><br/></ul><br/>M 38<br/>L 40<br/>XL 42<br/>XXL 44<br/><span data-mce-fragment=\"1\"><strong>Since these are slim fit, go for a size up your regular size.</strong> We have no returns/exchange as the range being very reasonable. We have sold 25000+ units - Feel free to read our instagram reviews - there are thounsands of customers pictures too. Since these units go sold out everytime in 1-2 hours of launch - we wont be able to assure same prints again once sold out. Please be little prompt and once you get your hands on them, you would love them again and again is what our regular buyers say! Happy shopping!</span><br/></div>",
     price: 129,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/WhatsAppImage2023-03-06at11.25.31PM.jpg"]
   },
   {
     id: 4440,
     title: "Mustard White flowers Slim Fit Suit Set",
     desc: "<div><br/>This is a two piece set includes kurta and pants.<br/>cotton kurta with cotton pants, Half drawstring and half elastic closure.<br/><ul class=\"iformation\"><br/> \t<li>MATERIALS: cotton mix</li><br/> \t<li>CARE: Hand Wash Separately</li><br/> \t<li>Go for a size up.</li><br/></ul><br/>M 38<br/>L 40<br/>XL 42<br/>XXL 44<br/><span data-mce-fragment=\"1\"><strong>Since these are slim fit, go for a size up your regular size.</strong> We have no returns/exchange as the range being very reasonable. We have sold 25000+ units - Feel free to read our instagram reviews - there are thounsands of customers pictures too. Since these units go sold out everytime in 1-2 hours of launch - we wont be able to assure same prints again once sold out. Please be little prompt and once you get your hands on them, you would love them again and again is what our regular buyers say! Happy shopping!</span><br/></div>",
     price: 129,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/WhatsAppImage2023-03-06at11.25.12PM.jpg"]
   },
   {
     id: 4465,
     title: "Sky Blue Floral Boom Slim Fit Suit Set",
     desc: "<div><br/>This is a two piece set includes kurta and pants.<br/>cotton kurta with cotton pants, Half drawstring and half elastic closure.<br/><ul class=\"iformation\"><br/> \t<li>MATERIALS: cotton mix</li><br/> \t<li>CARE: Hand Wash Separately</li><br/> \t<li>Go for a size up.</li><br/></ul><br/>M 38<br/>L 40<br/>XL 42<br/>XXL 44<br/><span data-mce-fragment=\"1\"><strong>Since these are slim fit, go for a size up your regular size.</strong> We have no returns/exchange as the range being very reasonable. We have sold 25000+ units - Feel free to read our instagram reviews - there are thounsands of customers pictures too. Since these units go sold out everytime in 1-2 hours of launch - we wont be able to assure same prints again once sold out. Please be little prompt and once you get your hands on them, you would love them again and again is what our regular buyers say! Happy shopping!</span><br/></div>",
     price: 99,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/WhatsAppImage2023-03-06at11.25.04PM.jpg"]
   },
   {
     id: 4427,
     title: "Black & White Mirror Slim Fit Suit Set",
     desc: "<div><br/>This is a two piece set includes kurta and pants.<br/>cotton kurta with cotton pants, Half drawstring and half elastic closure.<br/><ul class=\"iformation\"><br/> \t<li>MATERIALS: cotton mix</li><br/> \t<li>CARE: Hand Wash Separately</li><br/> \t<li>Go for a size up.</li><br/></ul><br/>M 38<br/>L 40<br/>XL 42<br/>XXL 44<br/><span data-mce-fragment=\"1\">Since these are slim fit, go for a size up your regular size. We have no returns/exchange as the range being very reasonable. We have sold 25000+ units - Feel free to read our instagram reviews - there are thounsands of customers pictures too. Since these units go sold out everytime in 1-2 hours of launch - we wont be able to assure same prints again once sold out. Please be little prompt and once you get your hands on them, you would love them again and again is what our regular buyers say! Happy shopping!</span><br/></div>",
     price: 99,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/WhatsAppImage2023-03-06at11.24.44PM.jpg"]
   },
   {
     id: 4435,
     title: "Handwoven cotton Sequin Suit : Black",
     desc: "<div><br/><b>Summer ocassions or regular days deserves elegant ensembles such as our Relaxing Suit Set.</b><br/><b>This is a 3 piece set including:</b><br/><b>Designer handwoven kurti pant dupatta set with heavy dori adda sequin embroidery work on yoke.</b><br/><b>Fabric :- Premium handwoven cotton</b><br/>Kurta pants Dupatta<br/></div>",
     price: 149,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/Kesariyavanitycottondesignersuit.jpg"]
   },
   {
     id: 4456,
     title: "Sky bandhej Slim Fit Suit Set",
     desc: "<div><br/>This is a two piece set includes kurta and pants.<br/>cotton kurta with cotton pants, Half drawstring and half elastic closure.<br/><ul class=\"iformation\"><br/> \t<li>MATERIALS: cotton mix</li><br/> \t<li>CARE: Hand Wash Separately</li><br/> \t<li>Go for a size up.</li><br/></ul><br/>M 38<br/>L 40<br/>XL 42<br/>XXL 44<br/><span data-mce-fragment=\"1\"><strong>Since these are slim fit, go for a size up your regular size.</strong> We have no returns/exchange as the range being very reasonable. We have sold 25000+ units - Feel free to read our instagram reviews - there are thounsands of customers pictures too. Since these units go sold out everytime in 1-2 hours of launch - we wont be able to assure same prints again once sold out. Please be little prompt and once you get your hands on them, you would love them again and again is what our regular buyers say! Happy shopping!</span><br/></div>",
     price: 129,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/IMG-20230314-WA0013.jpg"]
   },
   {
     id: 4430,
     title: "Cream Zari Floral Slim Fit Suit Set",
     desc: "<div><br/>This is a two piece set includes kurta and pants.<br/>cotton kurta with cotton pants, Half drawstring and half elastic closure.<br/><ul class=\"iformation\"><br/> \t<li>MATERIALS: cotton mix</li><br/> \t<li>CARE: Hand Wash Separately</li><br/> \t<li>Go for a size up.</li><br/></ul><br/>M 38<br/>L 40<br/>XL 42<br/>XXL 44<br/><span data-mce-fragment=\"1\">Since these are slim fit, go for a size up your regular size. We have no returns/exchange as the range being very reasonable. We have sold 25000+ units - Feel free to read our instagram reviews - there are thounsands of customers pictures too. Since these units go sold out everytime in 1-2 hours of launch - we wont be able to assure same prints again once sold out. Please be little prompt and once you get your hands on them, you would love them again and again is what our regular buyers say! Happy shopping!</span><br/></div>",
     price: 99,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/IMG-20230314-WA0010.jpg"]
   },
   {
     id: 4446,
     title: "Pink White Zari work Slim Fit Suit",
     desc: "<div><br/><b>Summer ocassions or regular days deserves elegant ensembles such as our Relaxing cotton Suit Set.</b><br/><b>This is a 3 piece set including:</b><br/>Kurta pants Dupatta<br/><strong>MATERIAL</strong><br/>Cotton Mix<br/>Soft Hand wash<br/>M 38<br/>L 40<br/>XL 42<br/>XXL 44<br/>Since these are slim fit, <strong>go for a size up your regular size.</strong> We have no returns/exchange as the range being very reasonable. We have sold 25000+ units - Feel free to read our instagram reviews - there are thounsands of customers pictures too. Since these units go sold out everytime in 1-2 hours of launch - we wont be able to assure same prints again once sold out. Please be little prompt and once you get your hands on them, you would love them again and again is what our regular buyers say! Happy shopping!<br/></div>",
     price: 99,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/IMG-20230314-WA0008.jpg"]
   },
   {
     id: 4467,
     title: "Sky white mirror yoke Slim Fit Suit Set",
     desc: "<div><br/>This is a two piece set includes kurta and pants.<br/>cotton kurta with cotton pants, Half drawstring and half elastic closure.<br/><ul class=\"iformation\"><br/> \t<li>MATERIALS: cotton mix</li><br/> \t<li>CARE: Hand Wash Separately</li><br/> \t<li>Go for a size up.</li><br/></ul><br/>M 38<br/>L 40<br/>XL 42<br/>XXL 44<br/><span data-mce-fragment=\"1\"><strong>Since these are slim fit, go for a size up your regular size.</strong> We have no returns/exchange as the range being very reasonable. We have sold 25000+ units - Feel free to read our instagram reviews - there are thounsands of customers pictures too. Since these units go sold out everytime in 1-2 hours of launch - we wont be able to assure same prints again once sold out. Please be little prompt and once you get your hands on them, you would love them again and again is what our regular buyers say! Happy shopping!</span><br/></div>",
     price: 99,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/IMG-20230314-WA0006.jpg"]
   },
   {
     id: 4432,
     title: "Grey white ikkat Slim Fit Suit Set",
     desc: "<div><br/>This is a two piece set includes kurta and pants.<br/>cotton kurta with cotton pants, Half drawstring and half elastic closure.<br/><ul class=\"iformation\"><br/> \t<li>MATERIALS: cotton mix</li><br/> \t<li>CARE: Hand Wash Separately</li><br/> \t<li>Go for a size up.</li><br/></ul><br/>M 38<br/>L 40<br/>XL 42<br/>XXL 44<br/><span data-mce-fragment=\"1\">Since these are slim fit, go for a size up your regular size. We have no returns/exchange as the range being very reasonable. We have sold 25000+ units - Feel free to read our instagram reviews - there are thounsands of customers pictures too. Since these units go sold out everytime in 1-2 hours of launch - we wont be able to assure same prints again once sold out. Please be little prompt and once you get your hands on them, you would love them again and again is what our regular buyers say! Happy shopping!</span><br/></div>",
     price: 99,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/IMG-20230314-WA0005.jpg"]
   },
   {
     id: 4468,
     title: "Turquoise White Floral Slim Fit Suit",
     desc: "<div><br/><b>Summer ocassions or regular days deserves elegant ensembles such as our Relaxing cotton Suit Set.</b><br/><b>This is a 3 piece set including:</b><br/>Kurta pants Dupatta<br/><strong>MATERIAL</strong><br/>Cotton 40-40<br/>Soft Hand wash<br/>M 38<br/>L 40<br/>XL 42<br/>XXL 44<br/>Since these are slim fit, go for a size up your regular size. We have no returns/exchange as the range being very reasonable. We have sold 25000+ units - Feel free to read our instagram reviews - there are thounsands of customers pictures too. Since these units go sold out everytime in 1-2 hours of launch - we wont be able to assure same prints again once sold out. Please be little prompt and once you get your hands on them, you would love them again and again is what our regular buyers say! Happy shopping!<br/></div>",
     price: 129,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/IMG-20230302-WA0084.jpg"]
   },
   {
     id: 4454,
     title: "SALE: Soft Pink Latte Sequin Suit Set",
     desc: "<div><br/><b>Special ocassions deserves elegant ensembles such as our  Soft Turquoise Sequin Suit Set.</b><br/>This three piece set includes kurta, pants and dupatta.<br/>Kurti Description - Kurta with sequin and dori work.<br/>Pants Description - soft white printed pants.<br/>Dupatta description -  Prettiest Maslin Dupatta.<br/>Suit Set Fabric:  soft maslin<br/>Sleeve: 3/4th sleeves<br/>Care: Dry Clean Only<br/>Model Size: Model is wearing M Size<br/>Model Height: 5'3\"<br/>DISCLAIMER: Slight Colour variations may occur due to different screen resolution.<br/></div>",
     price: 149,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/IMG-20221018-WA0076.jpg"]
   },
   {
     id: 4455,
     title: "SALE: Turquoise Handwork Suit Set",
     desc: "<div><br/>This is a two piece set includes kurta and pants.<br/>cotton kurta with cotton pants, Half drawstring and half elastic closure.<br/><ul class=\"iformation\"><br/> \t<li>MATERIALS:  cotton</li><br/> \t<li>CARE: Hand Wash Separately</li><br/></ul><br/><strong>SIZE &amp; FIT</strong><br/>The model (5'3\") is wearing size Medium<br/>Brand Label: Bust Size<br/>S :  38<br/>M :  40<br/>L:  42<br/>XL: 44<br/>XXL - 46<br/></div>",
     price: 129,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/IMG-20221018-WA0073_1.jpg"]
   },
   {
     id: 4452,
     title: "SALE: Onion Pink Handwork Romance Suit (3 piece)",
     desc: "<div><br/><b>Special ocassions deserves elegant ensembles such as our Romance Suit Set.</b><br/><b>This is a 3 piece set including:</b><br/>Kurta pants Dupatta<br/>Three-quarter sleeves<br/>Straight fit Kurta with Beautiful Handwork and Gottawork.<br/>Pants gives it a classy look put together.<br/>Pure Chiffon dupatta<br/>Material: Cotton Maslin Mix<br/>Bust Size:<br/>M-38<br/>L-40<br/>XL-42<br/>XXL-44<br/></div>",
     price: 129,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/IMG-20220806-WA0031_1.jpg"]
   },
   {
     id: 4478,
     title: "White Orange Block Print Cotton Suit Set",
     desc: "<div><br/>A three-piece set comprising a hand block printed straight kurta, straight pants with minimalistic details and cotton dupatta. This vivacious kurta set is a must-have in your wardrobe for the festivals and weddings you are going to attend. Accessorize it with your heavy jewellery and your magnetic smile and make all the eyes turn towards you.<br/><span data-mce-fragment=\"1\">Base Color: White</span><br data-mce-fragment=\"1\" /><span data-mce-fragment=\"1\">Fabric: Cotton</span><br data-mce-fragment=\"1\" /><span data-mce-fragment=\"1\">Sleeve: 3/4th</span><br data-mce-fragment=\"1\" /><span data-mce-fragment=\"1\">Kurta Length: 46\"</span><br data-mce-fragment=\"1\" /><span data-mce-fragment=\"1\">Hand Block Printed: Yes</span><br data-mce-fragment=\"1\" /><span data-mce-fragment=\"1\">CARE: Dry Clean Only</span><br/></div>",
     price: 99,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/IMG_9303_ca7b05e9-6a1b-4f71-9317-54186806ee84-scaled-1.jpg"]
   },
   {
     id: 4431,
     title: "Firozi Boota Angrakha Cotton Kurta Set with dupatta - 3 pc",
     desc: "<div><br/><span data-mce-fragment=\"1\">Minimal, classic and chic, this kurta is designed for all the days you want to keep things special and subtle. Inspired by the art of Hand Block Printing, this kurta is a piece of art. The kurta comes with a unique print that gives an elevated touch to its royal colour and pure cotton fabric.<br/>Base Color: Cream<br/>Fabric: Pure Cotton<br data-mce-fragment=\"1\" />Sleeve: 3/4th<br/>Kurta Length: 47\"<br data-mce-fragment=\"1\" />Hand Block Printed: Yes<br data-mce-fragment=\"1\" />CARE: Gentle Machine Wash<br/></span></div>",
     price: 99,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/IMG_4613-scaled-1.jpg"]
   },
   {
     id: 4441,
     title: "Pastel Grey Cotton Kurta Set - 3 pc",
     desc: "<div><br/><span data-mce-fragment=\"1\">Minimal, classic and chic, this kurta set is designed for all the days you want to keep things special and subtle. Inspired by the art of Hand Block Printing, this kurta set is a piece of art. The kurta set comes with a unique print that gives an elevated touch to its royal colour and pure cotton fabric.<br/>Base Color: Grey<br/>Fabric: Pure Cotton<br data-mce-fragment=\"1\" />Sleeve: 3/4th<br/>Kurta Length: 46\"<br data-mce-fragment=\"1\" />Hand Block Printed: Yes<br data-mce-fragment=\"1\" />CARE: Gentle Machine Wash<br/></span></div>",
     price: 99,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/IMG_4592_d137e13b-fd38-4c95-bb90-cb74fb8a85ad-scaled-1.jpg"]
   },
   {
     id: 4429,
     title: "Cream Mehendi \"Gold\" Cotton Kurta Set with Dupatta - 3 pc",
     desc: "<div><br/><span data-mce-fragment=\"1\">A three-piece set comprising a hand block printed kurta, straight pants with minimalistic details. This vivacious kurta set is a must-have in your wardrobe for the festivals and weddings you are going to attend. Accessorize it with your heavy jewellery and your magnetic smile and make all the eyes turn towards you.<br/>Base Color: Cream<br data-mce-fragment=\"1\" />Fabric: Cotton<br data-mce-fragment=\"1\" />Sleeve: 3/4th<br data-mce-fragment=\"1\" />Kurta Length: 46\"<br data-mce-fragment=\"1\" />Hand Block Printed: Yes<br data-mce-fragment=\"1\" />CARE: Dry Clean Only</span></div>",
     price: 129,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/IMG_4367-scaled-1.jpg"]
   },
   {
     id: 4434,
     title: "Hand Embroidered Black Ajrakh Afghan Pant Kurta Set with Dupatta - 3 pc",
     desc: "<div><br/><span data-mce-fragment=\"1\">Minimal, classic and chic, this kurta set is designed for all the days you want to keep things special and subtle. Inspired by the art of Hand Block Printing, this kurta is a piece of art. The kurta comes with Ajrakh print, hand embroidery, afghan pants &amp; cotton dupatta.<br/>Base Color: Black<br/>Fabric: Pure Cotton<br data-mce-fragment=\"1\" />Sleeve: 3/4th<br/>Kurta Length: 47\"<br data-mce-fragment=\"1\" />Hand Block Printed: Yes<br data-mce-fragment=\"1\" />CARE: Gentle Machine Wash<br/></span></div>",
     price: 129,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/IMG_4328-scaled-1.jpg"]
   },
   {
     id: 4477,
     title: "White Blue \"Aasman\" Angrakha Cotton Kurta Set - 2 pc",
     desc: "<div><span data-mce-fragment=\"1\"><span data-mce-fragment=\"1\">Minimal, classic and chic, this kurta set is designed for all the days you want to keep things special and subtle. Inspired by the art of Hand Block Printing, this angrakha kurta is a piece of art.</span></span>The set contains 1 kurta &amp; 1 pants.<br/>Base Color: White<br/>Fabric: Pure Cotton<br data-mce-fragment=\"1\" />Sleeve: 3/4th<br/>Kurta Length: 47\"<br data-mce-fragment=\"1\" />Hand Block Printed: Yes<br data-mce-fragment=\"1\" />CARE: Gentle Machine Wash<br/></div>",
     price: 99,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/IMG_4315-scaled-1.jpg"]
   },
   {
     id: 4453,
     title: "SALE: Pastel Turquoise Mirror Embroidered Suit Set",
     desc: "<div><br/><b>Special ocassions deserves elegant ensembles such as our beautiful Suit Set.</b><br/>This three piece set includes kurta, pants and dupatta.<br/>Kurti Description - Soft Chanderi Kurta with Mirror and Embroidery work.<br/>Pants Description - soft Chanderi pants.<br/>Dupatta description -  Prettiest Embroidery Organza Dupatta.<br/>Suit Set Fabric:  soft Chanderi<br/>Sleeve: 3/4th sleeves<br/>Care: Dry Clean Only<br/>Model Size: Model is wearing S Size<br/>Model Height: 5'3\"<br/>DISCLAIMER: Slight Colour variations may occur due to different screen resolution.<br/></div>",
     price: 129,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/IMG_20221103_142501_837.jpg"]
   },
   {
     id: 4451,
     title: "SALE: Inaayat Gulabi Chiffon Floral Suit Set",
     desc: "<div><br/><b>Special ocassions deserves elegant ensembles such as our beautiful Suit Set.</b><br/>This three piece set includes kurta, pants and dupatta.<br/>Kurti Description - Soft Chiffon Kurta with Embroidery work.<br/>Pants Description - soft Rayon pants.<br/>Dupatta description -  Prettiest Chiffon Dupatta.<br/>Suit Set Fabric:  soft Chiffon<br/>Sleeve: 3/4th sleeves<br/>Care: Dry Clean Only<br/>Model Size: Model is wearing M Size<br/>Model Height: 5'3\"<br/>DISCLAIMER: Slight Colour variations may occur due to different screen resolution.<br/></div>",
     price: 99,
     cancelprice: 1399,
     image: ["http://minimassagerforyou.shop/wp-content/uploads/2024/02/IMG_20221103_142501_705.jpg"]
   },
  ]
export default data